@font-face {
    font-family: PlusJakartaSans-ExtraBold;
    src: url(./fonts/PlusJakartaSans-ExtraBold-subset.woff2);
}

@font-face {
    font-family: "IBM Plex Sans";
    src: url(./fonts/IBMPlexSans-Regular.woff2);
}

body {
    margin: 0px;
    padding: 0px;
}

.section {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.section-120-60 {
    margin-top:120px;
    margin-bottom:60px;
}

.section-600 {
    min-height: 600px;
}

.feature-column {
    align-items:flex-start;
    opacity:0.4;
}

.feature-start {
    justify-content:flex-start;
}

.promo-caption {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
}

.padded {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 84%;
    text-align: center;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100vw;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 100;
    transition: .3s box-shadow;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.header-opaque {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.header-url {
    text-decoration: none;
}

.header-img {
    width:40px;
    height:40px;
    margin-left:20px;
    margin-right: 20px;
}

.header-img-round {
    width:40px;
    height:40px;
    border-radius: 20px;
    margin-left:20px
}

.header-name {
    margin-left:20px;
}

.subtitle {
    color: #333;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.4;
    /* letter-spacing: 0.011em; */
}

.header-nav-item {
    color: #333;
    font-weight: normal;
    font-size: 20px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.row-responsive {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.left {
    align-items: flex-start;
}

.button {
    background: #006efe;
    color: #fff;
    width: 200px;
    height: 59px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    transition: background .2s;
    border-color: transparent;
}

.button:hover {
    background: #117fff;
}

.only-very-small {
    display: none;
}

.only-small {
    display: none;
}

.only-large {
    display: block;
}

.hide-only-small {
    display: block;
}

.bottom-120 {
    margin-bottom: 120px;
}

.width-700 {
    max-width: 800px;
    margin-left: 20px;
    margin-right: 20px;
}

.width-fixed-700 {
    width: 100%;
    max-width: 700px;
}

.width-80 {
    max-width: 80%;
    margin-left: 20px;
    margin-right: 20px;
}

.margin-120 {
    margin-top: 120px;
    margin-bottom: 120px;
}

.equal-space {
    justify-content: space-between;
}

.link-header {
    padding: 0px;
    margin: 0px;
    margin-bottom: 4px;
}

.link-item {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-left: -8px;
    border-radius: 3px;
    transition: background .3s;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.2;
    text-decoration: none;
    color: #000;
}

.link-item:hover {
    background: #eee;
}

.footer-text {
    font-size: 12px;
    color: #444;
    margin-top: 4px;
}

.blue {
     color:#006efe;
}

.article-image {
    width: 14%;
    margin: 0%;
    height: auto;
    opacity: 0.5;
    cursor: pointer;
}

.provider-image {
    height: 80px;
    width: auto;
    margin: 18px;
}

.header-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    width: auto;
}

.header-blue-button {
    background: #006efe;
    color: #fff;
    width: auto;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    transition: background .2s;
    border-color: transparent;
    padding-left: 20px;
    padding-right: 20px;
}

.header-blue-button:hover {
    background: #117fff;
}

.header-right {
    margin-left: auto;
    margin-right: 20px;
}

.header-item {
    margin-right: 20px;
}

.faq-question {
    font-weight: 600;
    font-size: 17px;
    padding: 20px;
    text-align: center;
}

.faq-answer {
    font-weight: 400;
    font-size: 15px;
    padding: 20px;
    text-align: center;
    color: #999;
}

.tweet-container {
    width: 100%;
    height: auto;
}

.tweet-parent {
    width: 100%;
    height: 400px;
}

.tweet-card {
    max-width: 700px;
    width: 70%;
    height: auto;
}

.twitter-tweet {
    box-shadow: 2px 2px 40px 5px rgba(0, 0, 0, 0.1);
}

.carousel-container {
    width: 100%;
    height: auto;
}

.carousel-parent {
    width: 100%;
    height: auto;
}

.carousel-card {
    max-width: 700px;
    width: 100%;
    height: auto;
    padding: 40px;
    margin: 40px;
    border-radius: 20px;
    box-shadow: 2px 2px 40px 5px rgba(0, 0, 0, 0.1);
}

.carousel-link {
    text-decoration: none;
    color: inherit;
}

.badge {
    max-width: 170px;
    width: 100%;
    height: auto;
    padding: 20px;
    opacity: 0.5;
}

.footer-button {
    margin: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #999;
    transition: 0.3s color;
}

.footer-button:hover {
    color: #006efe;
}

.footer-subtitle {
    font-size: 15px;
    color: #999;
}

.flex-30-large {
    width: 30%;
    align-items: flex-start;
}

.flex-70-large {
    width: 70%;
    align-items: flex-end;
}

.flex-100-small {
    width: auto;
}

.footer-social {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 40px;
    color: #999;
    font-size: 20px;
    transition: 0.3s color;
}

.footer-social:hover {
    color: #006efe;
}

.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.modal {
    position: fixed;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal-close {
    position: absolute;
    top: 20px;
    right: 40px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.modal-card {
    width: 80%;
    height: auto;
    margin: 20px;
    background: #fff;
    max-width: 700px;
    max-height: 700px;
    border-radius: 20px;
    border: #bbb solid 1px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    overflow: auto;
}

.modal-card > h2 {
    font-size: 48px;
}

.modal-card > h2 > span {
    font-size: 48px;
}

.modal-input {
    /* height: 60px; */
    width: 300px;
    border: none;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    margin: 20px;
    font-size: 20px;
    color: #444;
    outline: none;
    border-radius: 20px;
    padding: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border: #bbb solid 1px;
}

.modal-input::placeholder {
    color: #bbb;
}

.modal-input:focus {
    outline: none;
}

.modal-button {
    width: 200px;
    padding: 20px;
    margin: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 700;
    transition: background .2s;
    background: #006efe;
    color: #fff;
}

.content-title {
    font-size: 64px;
    color: #000;
    text-align: left;
    padding: 20px;
    width: 100%;
}

.content-subtitle {
    font-size: 32px;
    /* font-weight: bold; */
    color: #000;
    text-align: left;
    padding: 20px;
}

.content-text {
    font-size: 16px;
    color: #999;
    text-align: left;
    padding: 20px;
    line-height: 24px;
}

.content-text > a {
    font-size: 16px;
    color: #006efe;
    text-align: left;
    line-height: 24px;
    text-decoration: none;
}

.blog-card {
    margin: 20px;
    margin-top: 0px;
    margin-bottom: 80px;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 700px;
    width: 80%;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    text-align: left;
    text-decoration: none;
    color: #000;
}

.blog-card-title {
    margin: 40px;
    margin-bottom: 20px;
    margin-top: 40px;
    font-size: 24px;
    font-weight: bold;
}

.blog-card-text {
    color: #999;
    margin: 40px;
    margin-top: 0px;
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 24px;
}

.enterprise-column {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.enterprise-card-parent {
    flex: 0 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.enterprise-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    width: 100%;
    height: auto;
    padding: 20px;
    margin: 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 40px 5px rgba(0, 0, 0, 0.1);
}

.enterprise-input {
    border: none;
    border-radius: 20px;
    border: solid #eee;
    padding: 20px;
    width: 200px;
}

.enterprise-input:focus {
    outline: none;
    /* box-shadow: 0px 0px 2px #0066ff; */
}

.enterprise-button {
    padding: 20px;
    font-weight: bold;
    background: #006efe;
    color: #fff;
    border-radius: 8px;
    margin: 20px;
    cursor: pointer;
    box-shadow: 2px 2px 40px 5px rgba(0, 0, 0, 0.1);
    transition: .2s background;
}

.enterprise-button:hover {
    background: #006efecc;
}

.enterprise-subtitle {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #aaa;
    text-align: center;
    margin: 20px;
}

.enterprise-modal {
    position: fixed;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.enterprise-modal-card {
    width: 90%;
    max-width: 600px;
    height: 80vh;
    margin: 0px;
    border-radius: 20px;
    border: none;
    background: #fff;
}

.enterprise-modal-card > iframe {
    height: 80%;
}

@media only screen and (max-width: 1500px) {
    .enterprise-column {
        flex-wrap: nowrap;
        flex-direction: column;
    }

    .enterprise-card-parent {
        flex: none;
        width: 100%;
    }
}

@media only screen and (max-width: 1300px) {
    .row-responsive-large {
        flex-direction: column;
    }

    .footer {
        flex-direction: column-reverse;
    }

    .flex-30-large {
        width: 100%;
        align-items: center;
    }

    .flex-70-large {
        width: 100%;
        align-items: center;
    }

    .flex-100-small {
        width: 100%;
    }

    .footer-social {
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media only screen and (max-width: 950px) {
    .subtitle {
        font-size: 21px;
    }

    .only-small {
        display: block;
    }

    .only-large {
        display: none;
    }

    .provider-image {
        width: 20%;
        margin: 6%;
        height: auto;
    }

    .article-image {
        width: 30%;
        margin: 4%;
        height: auto;
    }

    .carousel-card {
        width: 60%;
    }

    .footer-button {
        flex: 1 0 25%;
        font-size: 18px;
        margin: 1.5%;
    }
}

@media only screen and (max-width: 600px) {
    h1, h1 > span {
        font-size: 60px;
    }

    h2, h2 > span {
        font-size: 48px;
    }

    h3 {
        font-size: 16px;
    }

    .subtitle {
        font-size: 18px;
    }

    .badge {
        max-width: 120px;
    }

    .row-responsive {
        flex-direction: column;
    }

    .enterprise-button {
        margin-top: 20px;
        margin-left: 0px;
    }

    .enterprise-input {
        text-align: center;
    }

    .left {
        align-items: center;
    }

    .link-header {
        margin-top: 40px;
    }

    .link-item {
        margin-left: 0px;
    }

    .article-image {
        width: 30%;
        margin: 4%;
        height: auto;
    }

    .footer-button {
        font-size: 16px;
    }

    .carousel-card {
        width: 60%;
        padding: 20px;
        margin: 20px;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .hide-only-small {
        display: none;
    }

    .modal-card {
        width: 100vw;
        height: 100vh;
        max-width: none;
        max-height: none;
        margin: 0px;
        border-radius: 0px;
        border: none;
    }

    .header-button {
        height: 32px;
    }

    .only-very-small {
        display: block;
    }

    .blog-card {
        width: 100%;
        border-radius: 0;
        margin: 0 0px 40px;
    }

    .enterprise-card-parent {
        flex: none;
        width: 80%;
    }
}